import '../shared/wWaypoint';

var ec_tracking = {
  page_title: null,

  _addCartEvent: function(data){
    var me = this;

    if (window.ga) {
      ga('ec:addProduct', {
        'id':       data.id,
        'name':     data.name,
        'category': data.category,
        'brand':    data.brand,
        'variant':  data.variant,
        'price':    data.price,
        'quantity': data.quantity
      });
      ga('ec:setAction', 'add');
      ga('send', 'event', 'website-tracking-ec', 'click add-cart-btn', me.page_title);
    }

    if (window.fbq) fbq('track', 'AddToCart', {
      content_type: 'product',
      content_name: data.name + data.variant,
      currency:     'TWD',
      content_ids:  data.id,
      value:        data.price * data.quantity,
      contents: [{
        id:         data.id,
        name:       data.name + data.variant,
        category:   data.category,
        quantity:   data.quantity,
        item_price: data.price
      }]
    });
  },

  initProduct: function(){
    // 單一商品積木 block18
    var me = this;

    $('.component.product').each(function(){
      var $compProduct = $(this);

      if (!$compProduct) return false;

      var product_data = {
        id:       $compProduct.attr('data-product-id'),
        name:     $compProduct.find('.product-title').text(),
        category: $compProduct.attr('data-product-counter'),
        brand:    $compProduct.find('.product-brand').text(),
        price:    parseInt($compProduct.find('.product-price').attr('data-sale-price'))
      };

      var product_instances_data = [];

      $compProduct.find('.product-type input[type=radio]').parents('.product-type-name').each(function(index, elm){
        var $pi = $(elm);
        var pi_id = $pi.attr('data-type-pi-id');
        var pi_variant = $compProduct.find('.product-type-text').text();

        var product_instance_data = {
          'id':       pi_id,
          'name':     product_data.name,
          'category': product_data.category,
          'brand':    product_data.brand,
          'variant':  pi_variant,
          'price':    product_data.price
        };

        if (window.ga) ga('ec:addProduct', product_instance_data);
        product_instances_data.push(product_instance_data);
      });

      if (window.ga) {
        // 事件：商品細節/商品頁查看
        // FIXME: 範例跟著 pageview 送，這邊不得已才用 event 送，缺點就是會有多餘的 event 計數
        ga('ec:setAction', 'detail');
        ga('send', 'event', 'website-tracking-ec', 'product-detail-show', me.page_title, {'nonInteraction': 1});
      }

      if (window.fbq) fbq('track', 'ViewContent', {
        content_type: 'product_group',
        content_name: product_data.name,
        content_ids:  product_data.id,
        content_category: product_data.category
      });

      // 事件：加入購物車
      $compProduct.find('.product-cta').on('click', '.cta-btn', function(){
        var $this = $(this);
        var chosen_pi_id = $compProduct.find('.product-type input[type=radio]:checked').parents('.product-type-name').attr('data-type-pi-id');
        var data = product_instances_data.filter(function(pi){ return pi.id == chosen_pi_id; }).pop();
        data.quantity = parseInt($compProduct.find('.product-quantity input[type="number"]').val())

        me._addCartEvent(data);
      });
    });
  },

  initProductList: function(){
    var me = this;
    // Note: 商品列表積木不會有 instance

    var $product_lists = $('.four-products .product, .highlight-products .product');
    var products_data = {};
    var page_type = wUtil.pageCategory();

    $product_lists.each(function(index, product){
      $(product).attr('data-product-position', index + 1);
    });

    $product_lists.each(function(){
      var $this = $(this);
      var product_id = $this.attr('data-product-id');
      var product_instance_id = $this.attr('data-pi-id');

      var data = products_data[product_id] = {
        id:       product_instance_id,
        name:     $this.find('.product-name').text(),
        category: $this.attr('data-product-counter'),
        brand:    $this.attr('data-product-brand'),
        variant:  $this.attr('data-pi-name'),
        position: parseInt($this.attr('data-product-position')),
        price:    parseInt($this.find('.sale-price').attr('data-sale-price'))
      };

      // 列表曝光
      var inview = wWaypoint({
        element: $this[0],
        entered: function(direction) {
          if (window.ga) ga('ec:addImpression', {
            'id':       data.id,
            'name':     data.name,
            'category': data.category,
            'brand':    data.brand,
            'variant':  data.variant,
            'list':     page_type,
            'position': data.position
          });
        }
      });

      $this.find('.cta-btn').on('click', function(){
        var $product = $(this).parents('.product');
        var product_id = $product.attr('data-product-id');
        var data = products_data[product_id];
        data.quantity = 1;
        me._addCartEvent(data);
      });

      $this.on('click', function(){
        if (!window.ga) return;

        ga('ec:addProduct', {
          'id':       data.id,
          'name':     data.name,
          'category': data.category,
          'brand':    data.brand,
          'variant':  data.variant,
          'position': data.position
        });
        ga('ec:setAction', 'click', {list: me.page_type});
        ga('send', 'event', 'website-tracking-ec', 'product-clicked', me.page_title);
      })
    });
  },

  initMobileBtn: function(){
    var me = this;
    $('.component.add-cart-mobilebtn').each(function(){
      var $btn = $(this).find('.fixed-cta-btn');
      var product_id = $btn.attr('data-product-id');

      if (!product_id) return;

      var $product = $('.component.product[data-product-id=' + product_id + ']');

      $btn.on('click', function(){
        me._addCartEvent({
          'id':       $btn.attr('data-product-instance-id'),
          'name':     $product.find('.product-title').text(),
          'category': $product.attr('data-product-counter'),
          'brand':    $product.find('.product-brand').text(),
          'variant':  $product.find('.product-type-text:first').text(),
          'price':    parseInt($product.find('.product-price').attr('data-sale-price')),
          'quantity': 1
        });
      });
    })
  },

  init: function(){
    var me = this;
    me.page_title = wUtil.pageCategory() + ' - ' + $('title').text().replace(/｜女人迷 Womany/g, '');

    if (window.ga) ga('require', 'ec');

    me.initProduct();
    me.initProductList();
    me.initMobileBtn();
  }
};

ec_tracking.init();
